<template>
  <div class="flex h-full bg-cream">
    <div class="m-auto w-full">
      <div class="flex items-start justify-center space-x-10">
        <BackButton class="mt-3" @onClick="goBack"/>
        <div class="flex flex-col text-lg mb-2 w-100">
          <p class="heading-l-500 mb-5">Report {{ reportUserText }}</p>
          <span class="mb-2">Please provide a reason for reporting this {{reportUserText}}.</span>
          <TextAreaInput v-model="form.reason" border-radius="rounded-xl" rows="7" fontSize="text-sm"/>

          <ButtonIcon
            :text="`Report ${reportUserText}`"
            font-weight="font-normal"
            x-padding="px-4"
            :loading="loading"
            :disabled="loading"
            class="w-full mt-8"
            @onClick="report"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapMutations, mapState } from 'vuex';
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";
import BackButton from "@/components/shared/buttons/BackButton";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: 'ReportUserModal',
  components: { BackButton, ButtonIcon, TextAreaInput },

  data() {
    return {
      loading: false,
      form: {
        reason: '',
        id: null
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      job: state => state.jobs.current,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    reportUserText() {
      return this.userIsPro ? 'business' : 'user';
    },
  },

  mounted() {
    this.form.id = this.userIsPro ? this.job.church.hire_managers[0].user_id : this.job.hiring_bid.pro.id
  },

  methods: {
    ...mapMutations({
      showModal: 'general/showModal',
    }),

    async report(){
      this.loading = true;
      try {
        const { data } = await api.post(`/users/${this.form.id}/user-reports`, { reason: this.form.reason });
        if (data.success) {
            this.$toast.success('Report saved successfully');
            this.goBack();
            this.form.reason = '';
        }
      } catch (error) {
        console.log(error);
        this.$toast.error('There was an error. Try again later.');
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.form.reason = '';
    },

    goBack() {
      this.showModal({ componentName: 'JobModal', props: { jobId: this.job.id }});
    },
  },
}
</script>